<template>
  <div class="flex md6 xs12 xl3">

    <vac-card
      :title="$t('TOP 15 PRODUCTS')"
      class="d-flex dashboard-contributors-list">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        header="Top Products"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions v-if="isExportable" :export-to-excel="exportToExcel" @togglePreview="togglePreview" is-preview/>

      <div>
        <va-button
          small
          slot="actions"
          class="btn--caps btn--small"
          :disabled="part === 0"
          @click="showPrev">
          {{ $t('Prev') }}
        </va-button>
        <va-button
          small
          slot="actions"
          class="btn--caps btn--small"
          :disabled="part >= maxStep - step"
          @click="showNext">
          {{ $t('Next') }}
        </va-button>
      </div>

      <va-inner-loading>
        <div
          class="mb-3"
          v-for="(product, idx) in chartData"
          :key="idx">
          <va-progress-bar
            v-if="product.isShow"
            :value="getPercent(product.doc_count)"
            :color="product.color">
            {{ product.doc_count }}
          </va-progress-bar>
          <p class="mt-2" v-if="product.isShow" v-html="product.key"></p>
        </div>
      </va-inner-loading>
    </vac-card>

  </div>

</template>

<script>
import HelperExcel from '../../services/Helpers/HelperExcel';
import Actions from "../actions/Actions";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";

export default {
  name: 'TopProducts',
  data() {
    return {
      step: 5,
      maxStep: 0,
      part: 0,
      loading: true,
      chartData: [],
      isPreview: false
    };
  },
  watch: {
    data: function (val) {
      this.chartData = val;
      this.part = 0;
      this.step = 5;
      this.maxStep = val.length;
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    progressMax: {
      type: Number,
      default: 0,
    },
    isExportable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Actions,
    ExcelPreviewModal
  },
  mounted() {
    this.chartData = this.data
    this.part = 0;
    this.step = 5;
    this.maxStep = this.data.length;
  },
  computed: {
    preparedData () {
      const returnObj = {}
      returnObj.labels = ["SKU ID", "MPN", "EAN", "Count"];
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }

      returnObj.datasets = [];


      this.data.forEach(record => {
        const obj = {
          data: [record.product || record.key, record.mpn, record.ean, record.doc_count]
        }
        returnObj.datasets.push(obj);
        let name = (record.product || record.key);
        name = name.replaceAll("\t", "");
        name = name.replaceAll("  ", " ");


        returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(name).length)
        returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(record.mpn).length)
        returnObj.maxLength[2] = Math.max(returnObj.maxLength[2], String(record.ean).length)
        returnObj.maxLength[3] = Math.max(returnObj.maxLength[3], String(record.doc_count).length)
      })

      return returnObj;
    }
  },
  methods: {
    exportToExcel() {
      const labels = ["SKU ID", "MPN", "EAN", "Count"];
      this.data.forEach(info => {
        info["SKU ID"] = info.product || info.key;
        info["mpn"] = info.mpn;
        info["ean"] = info.ean;
        info["Count"] = info.doc_count;
      });

      HelperExcel.exportToExcel({}, "Top Products", labels, this.data);
    },
    showNext() {
      this.part = this.part + 5;
      this.chartData = this.chartData.map((product, index) => {
        product.isShow = index >= this.part && index < this.step + this.part;
        return product;
      });
    },
    showPrev() {
      this.part = this.part - 5;
      this.chartData = this.chartData.map((product, index) => {
        product.isShow = index >= this.part && index < this.step + this.part;
        return product;
      });
    },
    getPercent(val) {
      return (val / this.progressMax) * 100;
    },
    togglePreview() {
      this.isPreview = !this.isPreview;
    }
  },
};
</script>

<style lang="scss">
.dashboard-contributors-list {
  .product-link {
    color: #000000;
    transition: 0.24s;

    i {
      font-size: 15px;
      margin-left: 8px;
    }

    &:hover {
      color: #4598db;
    }
  }
}
</style>
